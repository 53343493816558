import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createTailwindMerge, getDefaultConfig } from "tailwind-merge";
// import * as Sentry from "@sentry/vue";

// Vuex
import notifications from "./store/app/notifications";
import rootStyles from "./store/app/root-styles";
import { createStore } from "vuex";
const store = createStore({
    modules: {
        notifications,
        rootStyles,
    },
});

// Social sharing
import VueSocialSharing from "vue-social-sharing";

// ScrollTo
import VueScrollTo from "vue-scrollto";

// App
const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Prodct.nl";

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        const vm = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(store)
            .use(VueSocialSharing);

        vm.directive("scroll-to", VueScrollTo);

        // Sentry.init({
        //     app: vm,
        //     dsn: "https://eb48e42695744b0388295fbca28fb953@o1314536.ingest.sentry.io/6565511",
        //     // Set tracesSampleRate to 1.0 to capture 100%
        //     // of transactions for performance monitoring.
        //     // We recommend adjusting this value in production
        //     tracesSampleRate: 0.5,
        //     logErrors: true,
        // });

        vm.mount(el);
        return vm;
    },
    progress: {
        color: "#29d",
    },
});

window.flash = function (message, level = "success", icon = "") {
    store.dispatch("notifications/displayNotification", {
        text: message,
        type: level,
        icon: icon,
    });
};

// TW
export const twMerge = createTailwindMerge(getDefaultConfig, config => ({
    ...config,
    conflictingClassGroups: {
        ...config.conflictingClassGroups,
        "font-size":
            config.conflictingClassGroups["font-size"]?.filter(
                id => id !== "leading",
            ) || [],
    },
}));
